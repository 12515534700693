<template>
  <div>
    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="CostDetailsFn"
      :isShow.sync="costIsShow"
      v-if="options.expenseType == 'PROJECT_EXPENSE'"
    >
      <div slot="title" class="title">项目报销详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <el-descriptions direction="vertical" :column="6" border style="width: 100%">
          <el-descriptions-item label="单据编号">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>

          <el-descriptions-item label="申请人">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="部门">
            {{ costDetail.deptName }}
          </el-descriptions-item>

          <el-descriptions-item label="领款人">
            {{ costDetail.payeeName }}
          </el-descriptions-item>

          <el-descriptions-item label="申请日期">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="费用所属单位">
            {{ costDetail.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">费用类别</span>
            </template>
            <span style="color: #000; font-size: 14px">项目费用报销</span>
          </el-descriptions-item>

          <el-descriptions-item label="付款方式" :span="2">
            {{ costDetail.applyExpenseType | dict(applyExpenseType) }}
          </el-descriptions-item>

          <el-descriptions-item label="报销方式" :span="3">
            {{ costDetail.expenseMode | dict(expenseMode) }}
          </el-descriptions-item>
        </el-descriptions>

        <!-- 经费预支单据 -->
        <el-table
          v-if="costDetail.counteractExpenseList && costDetail.counteractExpenseList.length > 0"
          :data="costDetail.counteractExpenseList"
          border
          style="margin-top: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            align="center"
            prop="expenseNumber"
            min-width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            min-width="110"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <!-- <div style="color: red">（包括审批中的金额）</div> -->
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="冲销金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <el-descriptions title="" :column="2" border style="margin-top: 20px">
          <el-descriptions-item labelStyle="width:100px;" :span="1">
            <template slot="label"> 项目名称 </template>
            <span style="display: inline-block; line-height: 40px">{{
              costDetail.projectName
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item labelStyle="width:110px;">
            <template slot="label"> 项目承接单位 </template>
            <div style="line-height: 40px">
              {{ costDetail.projectCompanyType | dict(dictData.companyType) }}
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table :data="costDetail.detailList" border :row-style="{ height: '80px' }">
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

            <el-table-column
              prop="taskDate"
              label="日期"
              width="120"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="typeOne"
              label="费用科目一"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                费用科目一
                <div></div>
              </template>
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              label="费用科目二"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              label="费用科目三"
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
              </template>
            </el-table-column>

            <el-table-column
              prop="cost"
              label="金额 (元)"
              width="120"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              :label="'事由附件详细说明'"
              width="220"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="340"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="330"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>

        <div style="height: 20px"></div>

        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ costDetail.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="costDetail.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="costDetail.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item></template
            >
            <el-descriptions-item
              label="本次冲销总金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销支付金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>

        <el-descriptions
          :column="1"
          direction="vertical"
          style="margin-top: 10px"
          border
          v-if="costDetail.remark"
        >
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ costDetail.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
            </template>
            <template v-for="item in costDetail.affirmCostFileList">
              <a
                :key="item.id"
                target="_blank"
                style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                :href="`${filepath}${item.filePath}`"
                >{{ item.fileName }}</a
              >
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <div style="height: 40px"></div>

        <OpinionArea
          ref="opinionArea"
          :procInstId="costDetail.processInstId"
          :instInvolved="instInvolved"
          :title="'项目报销进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="CostDetailsFn">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="CostDetailsFn"
      :isShow.sync="costIsShow"
      v-if="options.expenseType == 'BUSINESS_EXPENSE'"
    >
      <div slot="title" class="title">商务报销详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <el-descriptions direction="vertical" :column="6" border style="width: 100%">
          <el-descriptions-item label="单据编号">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>
          <el-descriptions-item label="申请人">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="部门">
            {{ costDetail.deptName }}
          </el-descriptions-item>
          <el-descriptions-item label="领款人">
            {{ costDetail.payeeName }}
          </el-descriptions-item>

          <el-descriptions-item label="申请日期">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="费用所属单位">
            {{ costDetail.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">费用类别</span>
            </template>
            <span style="color: #000; font-size: 17px">商务费用报销</span>
          </el-descriptions-item>

          <el-descriptions-item label="付款方式" :span="2">
            {{ costDetail.applyExpenseType | dict(applyExpenseType) }}
          </el-descriptions-item>

          <el-descriptions-item label="报销方式" :span="2">
            {{ costDetail.expenseMode | dict(expenseMode) }}
          </el-descriptions-item>
        </el-descriptions>

        <!-- 经费预支单据 -->
        <el-table
          v-if="costDetail.counteractExpenseList && costDetail.counteractExpenseList.length > 0"
          :data="costDetail.counteractExpenseList"
          border
          style="margin-bottom: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            align="center"
            prop="expenseNumber"
            min-width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            min-width="110"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <!-- <div style="color: red">（包括审批中的金额）</div> -->
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="冲销金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>

        <el-descriptions title="" :column="1" border>
          <el-descriptions-item :span="1" labelStyle="width:180px;">
            <template slot="label">支出类型</template>
            {{ costDetail.disbursementType | dict(dictData.disbursementType) }}
          </el-descriptions-item>
          <el-descriptions-item labelStyle="width:100px;">
            <template slot="label">
              <template v-if="costDetail.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
                商务说明</template
              >
              <template v-else> 商务名称</template>
            </template>
            <template v-if="costDetail.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
              ><span style="display: inline-block; line-height: 40px; width: 70%">{{
                costDetail.businessContent
              }}</span></template
            >
            <template v-else>
              <span style="display: inline-block; line-height: 40px; width: 70%">{{
                costDetail.businessName
              }}</span>
            </template>
          </el-descriptions-item>
        </el-descriptions>

        <el-table :data="costDetail.detailList" border>
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            prop="taskDate"
            label="日期"
            min-width="170"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="typeOne"
            label="费用科目一"
            width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTwo"
            label="费用科目二"
            width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeThree"
            label="费用科目三"
            width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="金额 (元)"
            min-width="120"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.cost | applyAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            scoped-slot
            prop="contentDetail"
            label="事由附件详细说明"
            width="220"
            :show-overflow-tooltip="false"
          >
          </el-table-column>
          <el-table-column
            prop="estimatedRefundDate"
            label="预计退回时间"
            width="160"
            v-if="EstimatedShow"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                  scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                "
              >
                {{ scope.row.estimatedRefundDate | dateFormat }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            min-width="340"
            label="发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot="header">
              <div>发票附件上传</div>
              <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
            </template>
            <template slot-scope="scope">
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                class="invoice-file"
              >
                <template v-if="n.fileType === 'INVOICE'">
                  <div class="invoice-file-left">
                    <div>
                      <a
                        v-if="isShowType(n)"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        target="_blank"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                      <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                        n.fileName
                      }}</span>
                    </div>
                    <div>
                      <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                        >发票识别存疑</span
                      >
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            width="330"
            label="非发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                style="margin-top: 5px"
              >
                <template v-if="n.fileType === 'NORMAL'">
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ costDetail.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="costDetail.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="costDetail.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item></template
            >
            <el-descriptions-item
              label="本次冲销总金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销支付金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
        <el-descriptions
          :column="1"
          direction="vertical"
          style="margin-top: 10px"
          border
          v-if="costDetail.remark"
        >
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ costDetail.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
            </template>
            <template v-for="item in costDetail.affirmCostFileList">
              <a
                :key="item.id"
                target="_blank"
                style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                :href="`${filepath}${item.filePath}`"
                >{{ item.fileName }}</a
              >
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <div style="height: 40px"></div>
        <OpinionArea
          ref="opinionArea"
          :procInstId="costDetail.processInstId"
          :instInvolved="instInvolved"
          :title="'商务报销进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="CostDetailsFn">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="CostDetailsFn"
      :isShow.sync="costIsShow"
      v-if="options.expenseType == 'PUBLIC_SPENDING_EXPENSE'"
    >
      <div slot="title" class="title">公共费用报销详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <el-descriptions direction="vertical" :column="columnCount" border style="width: 100%">
          <el-descriptions-item label="单据编号">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>

          <el-descriptions-item label="申请人">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="费用所属部门">
            {{ costDetail.deptName }}
          </el-descriptions-item>

          <el-descriptions-item label="领款人">{{ costDetail.payeeName }} </el-descriptions-item>

          <el-descriptions-item label="申请日期">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="费用所属单位">
            {{ costDetail.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>

          <el-descriptions-item
            :span="1"
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">费用类别</span>
            </template>
            <span style="color: #000; font-size: 17px">公共费用报销</span>
          </el-descriptions-item>
          <el-descriptions-item label="付款方式" :span="2">
            {{ costDetail.applyExpenseType | dict(applyExpenseType) }}
          </el-descriptions-item>

          <el-descriptions-item label="报销方式" :span="2">
            {{ costDetail.expenseMode | dict(expenseMode) }}
          </el-descriptions-item>
        </el-descriptions>
        <!-- 经费预支单据 -->
        <el-table
          v-if="costDetail.counteractExpenseList && costDetail.counteractExpenseList.length > 0"
          :data="costDetail.counteractExpenseList"
          border
          style="margin-bottom: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            align="center"
            prop="expenseNumber"
            min-width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            min-width="110"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销金额</div>
              <!-- <div style="color: red">（包括审批中的金额）</div> -->
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            label="冲销金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>

        <el-table :data="costDetail.detailList" border :row-style="{ height: '80px' }">
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

          <el-table-column
            prop="taskDate"
            label="日期"
            align="center"
            min-width="170"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="typeOne"
            label="费用科目一"
            width="160"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTwo"
            label="费用科目二"
            width="160"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
            </template>
          </el-table-column>
          <el-table-column
            prop="typeThree"
            label="费用科目三"
            width="160"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
            </template>
          </el-table-column>

          <el-table-column
            prop="cost"
            align="center"
            label="金额 (元)"
            width="120"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.cost | applyAmount }} </template>
          </el-table-column>
          <el-table-column
            scoped-slot
            prop="contentDetail"
            :label="'事由附件详细说明'"
            width="220"
            :show-overflow-tooltip="false"
          >
          </el-table-column>
          <el-table-column
            prop="estimatedRefundDate"
            label="预计退回时间"
            width="160"
            v-if="EstimatedShow"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                  scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                "
              >
                {{ scope.row.estimatedRefundDate | dateFormat }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            min-width="340"
            label="发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot="header">
              <div>发票附件上传</div>
              <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
            </template>
            <template slot-scope="scope">
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                class="invoice-file"
              >
                <template v-if="n.fileType === 'INVOICE'">
                  <div class="invoice-file-left">
                    <div>
                      <a
                        v-if="isShowType(n)"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        target="_blank"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                      <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                        n.fileName
                      }}</span>
                    </div>
                    <div>
                      <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                        >发票识别存疑</span
                      >
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            width="330"
            label="非发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                style="margin-top: 5px"
              >
                <template v-if="n.fileType === 'NORMAL'">
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 合计内容 -->
        <el-descriptions title="" :column="4" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:120px"
          >
            {{ costDetail.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item
            label="报销合计"
            :span="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
            labelStyle="width:120px"
          >
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="金额大写" labelStyle="width:120px">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
          <template v-if="costDetail.applyExpenseType == 'ADVANCE_PAYMENT'">
            <template v-if="costDetail.status != 100">
              <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                {{ totalCounteract.sumAdvanceCost | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item labelStyle="width:150px">
                <template slot="label">
                  <div>　冲销中的金额</div>
                  <div>（包括本单的冲销）</div>
                </template>

                {{ writeOffMoney | applyAmount }}
              </el-descriptions-item></template
            >
            <el-descriptions-item
              label="本次冲销总金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ thisTimeMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销支付金额"
              :span="costDetail.status != 100 ? 1 : 2"
              labelStyle="width:120px"
            >
              <template v-if="paymentMony">
                {{ paymentMony | applyAmount }}
              </template>
              <template v-else> 0 </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
        <el-descriptions
          :column="1"
          direction="vertical"
          style="margin-top: 10px"
          border
          v-if="costDetail.remark"
        >
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ costDetail.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
            </template>
            <template v-for="item in costDetail.affirmCostFileList">
              <a
                :key="item.id"
                target="_blank"
                style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                :href="`${filepath}${item.filePath}`"
                >{{ item.fileName }}</a
              >
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <div style="height: 40px"></div>
        <OpinionArea
          ref="opinionArea"
          :procInstId="costDetail.processInstId"
          :instInvolved="instInvolved"
          :title="'公共费用报销进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="CostDetailsFn">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="CostDetailsFn"
      :isShow.sync="costIsShow"
      v-if="options.expenseType == 'RETURN_EXPENDITURE'"
    >
      <div slot="title" class="title">经费预支归还单详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <el-descriptions direction="vertical" :column="7" border style="width: 100%">
          <el-descriptions-item label="单据编号" labelClassName="labelClassNameId">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>

          <el-descriptions-item label="申请人" labelClassName="labelClassNameId">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="部门" labelClassName="labelClassNameId">
            {{ costDetail.deptName }}
          </el-descriptions-item>

          <el-descriptions-item label="申请日期" labelClassName="labelClassNameId">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="归还日期" labelClassName="labelClassNameId">
            {{ costDetail.counteractDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item labelClassName="labelClassNameId">
            <template slot="label"> 归还总金额（元） </template>
            {{ costDetail.cost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" style="width: 100%">
            {{ costDetail.cost | numberParseChina }}
          </el-descriptions-item>
          <el-descriptions-item label="归还说明" :span="5">
            {{ costDetail.remark }}
          </el-descriptions-item>
          <el-descriptions-item label="凭证附件" :span="2">
            <div
              v-for="(n, index) in costDetail.expenseFileList"
              :key="index"
              style="margin-top: 5px"
            >
              <a
                v-if="isShowType(n)"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                target="_blank"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>
              <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <!-- 经费预支单据 -->
        <el-table
          v-if="costDetail.counteractExpenseList && costDetail.counteractExpenseList.length > 0"
          :data="costDetail.counteractExpenseList"
          border
          style="margin-bottom: 10px"
          :row-style="{ height: '80px' }"
        >
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
          <el-table-column
            align="center"
            prop="expenseNumber"
            min-width="160"
            label="单据编号"
          ></el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            min-width="110"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cost" label="预支总金额">
            <template slot-scope="scope">
              {{ scope.row.cost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sumNotCounteractCost">
            <template slot="header" slot-scope="scope">
              <div>未冲销/归还总金额</div>
            </template>
            <template slot-scope="scope">
              {{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="counteractCost"
            label="归还金额"
            align="center"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <OpinionArea
          ref="opinionArea"
          :procInstId="costDetail.processInstId"
          :instInvolved="instInvolved"
          :title="'公共费用报销进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="CostDetailsFn">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>

    <!-- 经费预支详情 -->
    <!-- <ExpenditureAdvanceDetails
      v-if="costDetail.counteractExpenseList && costDetail.counteractExpenseList.length > 0"
      :isShow.sync="expenditureIsShow"
      :options="expenditureOptions"
      @closeExpenditure="expenditureIsShow = false"
    ></ExpenditureAdvanceDetails> -->
  </div>
</template>

<script>
export default {
  name: 'CostDetails',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
  },
  data() {
    return {
      img_index: 0, //附件图片预览下标
      dialogVisible: false, //图片预览
      loading: false,
      filepath: process.env.VUE_APP_FILEPATH,
      costDetail: {},
      dictData: {
        companyType: [],
        expenseType: [],
        disbursementType: [],
      },
      imgList: [],
      applyExpenseType: [], //付款方式字典
      expenseMode: [], //报销方式
      totalCounteract: {
        sumAdvanceCost: 0, //经费预支总合计
        sumCounteractCost: 0, //	冲销中金额合计
      },
      // expenditureOptions: {}, //经费预支-详情
      // expenditureIsShow: false, //经费预支-详情
      costIsShow: false, //弹窗
      instInvolved: {}, //当前进度处理人数组
    }
  },
  props: {
    costDetailDialog_show: {
      type: Boolean,
      default: false,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('EXPENSE_MODE', true)
      .then(res => {
        this.expenseMode = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('EXPENSE_TYPE', true)
      .then(res => {
        this.dictData.expenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDetail.detailList) {
        sum = this.costDetail.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      return sum
    },

    // 获取当前描述列表的列数
    columnCount() {
      if (this.costDetail.registrationType == 'KITCHEN' && this.costDetail.expenseNumber) {
        // 厨房登记且存在单据编号
        return 8
      } else if (
        this.costDetail.registrationType == 'INVENTORY_KEEPER' &&
        this.costDetail.expenseNumber
      ) {
        // 库管登记且存在单据编号
        return 6
      } else if (
        this.costDetail.registrationType == 'EXTERNAL_SPENDING' &&
        !this.costDetail.expenseNumber
      ) {
        // 外部登记且存在单据编号
        return 5
      } else if (
        this.costDetail.registrationType == 'EXTERNAL_SPENDING' &&
        this.costDetail.expenseNumber
      ) {
        // 外部登记且不存在单据编号
        return 6
      }
    },
    columnName() {
      switch (this.columnCount) {
        case 4:
          // 新增外部登记只占一格
          return 1
        case 5:
          return 2
        case 6:
          return 4
        case 8:
          return 6
      }
    },
    // 冲销中的金额
    writeOffMoney() {
      let num = 0
      num = this.totalCounteract.sumCounteractCost + this.thisTimeMoney
      return num
    },
    // 本次冲销金额
    thisTimeMoney() {
      let num = 0
      if (this.costDetail.counteractExpenseList) {
        this.costDetail.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 报销支付金额
    paymentMony() {
      let num = 0
      num = this.amount - this.thisTimeMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    EstimatedShow() {
      if (this.costDetail.detailList) {
        const item = this.costDetail.detailList.find(
          v => v.typeTwo === 'tou_bao_zheng_jin' || v.typeTwo === 'lv_yue_bao_zheng_jin'
        )

        if (item) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  watch: {
    costDetailDialog_show: {
      immediate: true,
      handler: function (val) {
        this.costIsShow = val
      },
    },
    options: {
      deep: true,
      // immediate: true,
      handler: function (val) {
        if (val.expenseType === 'PROJECT_EXPENSE') {
          this.projectCostFn(val.expenseId)
        } else if (val.expenseType === 'BUSINESS_EXPENSE') {
          this.businessCostFn(val.expenseId)
        } else if (val.expenseType === 'PUBLIC_SPENDING_EXPENSE') {
          this.pubicCostFn(val.expenseId)
        } else if (val.expenseType === 'RETURN_EXPENDITURE') {
          this.fundsCostFn(val.expenseId)
        }
      },
    },
  },
  methods: {
    // 经费预支详情
    // expenditureDetailFn(row) {
    //   this.expenditureOptions = { ...row, id: row.advanceId }
    //   this.expenditureIsShow = true
    // },
    // 经费预支归还
    fundsCostFn(id) {
      this.loading = true
      this.$api.fundsGiveBack
        .selectDetailById(id)
        .then(res => {
          if (res.data) {
            this.costDetail = res.data
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            this.loading = false
            if (res.data.expenseFileList) {
              this.imgList = []
              res.data.expenseFileList.forEach(v => {
                if (!this.isShowType(v)) {
                  this.imgList.push(v)
                }
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 项目
    projectCostFn(id) {
      this.loading = true
      this.$api.projectCost
        .selectDetailById(id)
        .then(res => {
          if (res.data) {
            this.costDetail = res.data
            this.totalCounteract.sumAdvanceCost = this.costDetail.sumAdvanceCost
              ? this.costDetail.sumAdvanceCost
              : 0
            this.totalCounteract.sumCounteractCost = this.costDetail.sumCounteractCost
              ? this.costDetail.sumCounteractCost
              : 0
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            this.loading = false
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 商务
    businessCostFn(id) {
      this.loading = true
      this.$api.businessExpense
        .selectDetailById(id)
        .then(res => {
          if (res.data) {
            this.costDetail = res.data
            this.totalCounteract.sumAdvanceCost = this.costDetail.sumAdvanceCost
              ? this.costDetail.sumAdvanceCost
              : 0
            this.totalCounteract.sumCounteractCost = this.costDetail.sumCounteractCost
              ? this.costDetail.sumCounteractCost
              : 0
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            this.loading = false
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 公共
    pubicCostFn(id) {
      this.loading = true
      this.$api.publicSpending
        .selectDetailById(id)
        .then(res => {
          if (res.data) {
            this.costDetail = res.data
            this.totalCounteract.sumAdvanceCost = this.costDetail.sumAdvanceCost
              ? this.costDetail.sumAdvanceCost
              : 0
            this.totalCounteract.sumCounteractCost = this.costDetail.sumCounteractCost
              ? this.costDetail.sumCounteractCost
              : 0
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
            this.loading = false
            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (v.id === n.id || (v.fileName === n.fileName && v.filePath === n.filePath)) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    // 关闭详情弹框
    CostDetailsFn() {
      this.$emit('CostDetailsFn')
    },
  },
}
</script>

<style lang="scss" scoped>
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe !important;
  border: 1px solid #ddd !important;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.labelClassNameId {
  width: 13%;
}
</style>
